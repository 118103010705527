<template>
	<div
		class="mt-30 cartel detail pb-150"
	>
		<div class="flex-row">
			<div
				class="box-contents mr-30"
			>
				<div
					class="bg-white radius-20 mb-30"
				>
					<div class=" flex-row justify-space-between pa-20 under-line">

						<div
							v-if="is_notice"
						>{{ item_bbs.post_title }}</div>
						<div
							v-else
							class="flex-row"
						>
							<ProfileImage
								:item_info="item_bbs"
								:cartel_info="item_cartel"
								icon_type="ss"
							></ProfileImage>
							<div class="flex-column justify-center font-weight-500 color-333" style="">{{  item_bbs.post_member_nickname }}</div>
						</div>
						<div class="color-888 flex-row align-center gap-10 size-px-14">
							{{ getTimeStory('item_bbs', item_bbs.post_registdate)}}
							<button
								class="post_set"
								@click="onMore"
							><v-icon>mdi mdi-dots-vertical</v-icon><span class="hide">설정</span></button>
						</div>
					</div>

					<div>
						<CartelBbsFile
							v-if="item_bbs.post_file_list && item_bbs.post_file_list.length > 0"
							:user="user"
							:item="item_bbs"
							:index="0"

						></CartelBbsFile>

						<div
							class="pa-20 text-left size-px-14 color-333"
							style="min-height: 240px"
							:inner-html.prop="item_bbs.post_contents | nl2br"
						></div>
					</div>

					<mafia_vote
						v-if="item_bbs.vote_fg == 'Y'"
						:cartel="item_cartel"
						:vote="item_bbs.vote"
						:bbs="item_bbs"
						:user="user"

						type="vote"
						@click="item_bbs.vote_fg = 'N'; getBbs()"
					></mafia_vote>

					<!-- 공감하기 -->
					<mafia_emoji
						v-if="item_bbs.bulletin_number"
						:cartel="item_cartel"
						:bbs="item_bbs"
						:user="user"
						:list="item_bbs.sympaty_list"

					></mafia_emoji>
				</div>
			</div>

			<CartelEtc
				v-if="item_cartel.cartl_member_grade_chg_info"
				:user="user"
				:cartel_info="item_cartel"
				:bbs_info="item_bbs"

			></CartelEtc>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_bbs"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="mount"
			@deleteBbs="deleteBbs"
			@modify="toModify"

			:user="user"
			:item_bbs="item_bbs"
			style="z-index: 1003"
		>
		</mafia064>
	</div>
</template>

<script>
	import mafia_vote from '@/view/Cartel/mafiaVote'

	import mafia_emoji from '@/view/Cartel/mafiaEmoji'
	import mafia064 from "@/view/Cartel/mafia064";
	import mafia098 from '@/view/Cartel/mafia098'
	import CartelEtc from "@/view/Cartel/CartelEtc";
	import ProfileImage from "@/components/Daum/ProfileImage";
	import CartelBbsFile from "@/view/Cartel/CartelBbsFile";

	export default {
		name: 'mafia058'
		, props: ['user']
		, components: {
			CartelBbsFile,
			ProfileImage, CartelEtc, mafia064, mafia_vote, mafia_emoji, mafia098}
		, data: function(){
			return {
				program: {
					name: '게시물 상세'
					, title: '게시물 상세'
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
					, from: this.$route.params.from
					, bg_contents: 'bg-gray01'
					, cartel: {}
				}
				, item_cartel: {}
				, item_board_config: {}
				, item_bbs: {
					post_registdate: ''
					, vote_fg: 'N'
				}
				, items_comment: []
				, rows: 1
				, item_comment: {
					cartel_id: this.$route.params.idx
					, bbs_code: this.$route.params.code
					, bbs_id: this.$route.params.bbs_id
					, user_id: this.user.id
					, user_nick: this.user.nick
					, user_img_src: this.user.img_src
					, upper_id: ''
					, upper_nick: ''
					, content: ''
				}
				, is_emoji: false
				, is_more: false
				, is_098: false
				, item_search: this.$storage.init({
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				})
			}
		}
		, computed: {
			text_title: function(){

				let t = this.item_bbs.nick

				if(this.$route.params.code == 'CA00700001'){
					t = this.item_bbs.title
				}

				return t
			}
			, is_notice: function(){
				let t = false
				if(this.$route.params.code == 'CA00700001'){
					t = true
				}
				return t
			}
			, video: function(){
				let t = []
				if(this.item_bbs.video){
					if(this.item_bbs.video.length > 0){
						t = this.item_bbs.video
					}
				}
				return t

			}
			, files: function(){
				let t = []
				if(this.item_bbs.post_file_list){
					if(this.item_bbs.post_file_list.length > 0){
						t = this.item_bbs.post_file_list
					}
				}
				return t

			}
			, sound: function(){
				let t = []
				if(this.item_bbs.sound){
					if(this.item_bbs.sound.length > 0){
						t = this.item_bbs.sound
					}
				}
				return t

			}
			, is_friend: function(){
				let t = false

				return t
			}
			, list_comment: function(){
				return this.items_comment.filter(function(item){
					if(item.blok_member_fg == 'Y'){
						item.is_block = true
					}
					return item
				})
			}
		}
		, methods: {

			getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.program.cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getBoardConfig: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})

					if(result.success){
						this.item_board_config = result.data
						await this.getBbs()
						// await this.getBbsComment()
					}else{
						if(result.code == 'E001201021'){
							this.$bus.$emit('notify', { type: 'error', message: result.message})
							this.$router.back()
						}
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getBbs: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_cartel_bbs_info
					if(this.$route.params.code == 'CA00700001') {
						url = this.$api_url.api_path.get_cartel_notice_info
					}else if(this.$route.params.code == 'CA00700002'){
							url = this.$api_url.api_path.get_cartel_cheer_info
					}else if(this.item_board_config.board_type_code == 'CA00700005'){
						url = this.$api_url.api_path.get_subscribe_board_info
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
						}
						, type: true
					})

					if(result.success){
						this.item_bbs = {}
						this.item_bbs = result.data
						this.$set(this.item_bbs, 'board_number', this.$route.params.b_id)
						this.item_bbs.vote = result.data.vote_info
						if(result.data.vote_info){
							this.item_bbs.vote.vote_participation_people_count = result.data.vote_participation_people_count
							this.item_bbs.vote.time = result.data.vote_info.vote_end_daytime
							this.item_bbs.vote.items = result.data.vote_list
						}

						this.items_comment = result.data.comment_list


						await this.getSubscribeFiles(this.item_bbs)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBbsComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_bbs_comment_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_comment = result.data.comment_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postComment: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw '댓글을 입력하세요'
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_comment
						, data: new Object(this.item_comment)
						, name: 'postComment'
					})

					if(result.success){
						if(this.item_comment.upper_id){
							for(let c of this.items_comment){
								if(c.id == this.item_comment.upper_id){
									this.$log.console('c', c)
									if(!c.reply){
										c.reply = []
									}
									c.reply.push(result.data)
								}
							}
						}else{
							this.items_comment.unshift(result.data)
						}
						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code
							, bbs_id: this.$route.params.bbs_id
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_id: ''
							, upper_nick: ''
							, content: ''
						}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, clearReply: function(){
				this.item_comment.upper_id = ''
				this.item_comment.upper_nick = ''
			}
			, setReply: function(comment){
				this.item_comment.upper_id = comment.upper_id
				this.item_comment.upper_nick = comment.user_nick
				//this.item_comment.content = '@' + comment.user_nick + ' '
			}
			, getTimeStory: function(type, created_at){
				this.$log.console(type, created_at)
				return this.$date.getTimeStory(created_at)
			}
			, setFile: function(e){
				this.$log.console(e)

				const reader = new FileReader()
				
				reader.onload = (e) => {
					this.$set(this.item_comment, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e.target.files[0])
			}
			, mount: async function(){

				await this.getCartel()
				await this.getBoardConfig()

				this.is_more = false
				// document.body.scrollTop = 0
			}

			, deleteBbs: async function(){
				this.$emit('goBack')
			}
			, goBack: function(){
				if(this.$route.params.code == 'CA00700001'){
					this.$bus.$emit('to', {name: 'mafia127', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
				}else{
					this.$emit('goBack')
				}
			}
			, toComment: function(){
				this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: this.$route.params.code, bbs_id: this.$route.params.bbs_id}})
			}
			, toModify: function(){
				this.$bus.$emit('to', {name: 'mafia1272', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
			}
			, toImage: function(file){
				this.$bus.$emit('viewer', file)
			}

			, onMore: function(){
				this.is_more = true
			}
			, getSubscribeFiles: async function(item){
				if(item.post_file_list && item.post_file_list.length > 0){
					for(let i = 0; i < item.post_file_list.length; i++){
						let file = item.post_file_list[i]
						// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
						// file.file_url = file.post_appendix_file_org_url

						switch (file.appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}

						switch (file.post_appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}

						// console.log(item.bulletin_number, file.post_appendix_file_div_code, file.post_appendix_file_org_url)

						let p_file = file.post_appendix_file_partial_list[0]
						if(p_file){

							file.cartl_number = item.cartl_number
							file.board_number = item.board_number
							file.bulletin_number = item.bulletin_number
							file.appendix_file_div_code = file.post_appendix_file_div_code
							file.appendix_file_url = p_file.appendix_file_url + (p_file.appendix_file_name ? '/' + p_file.appendix_file_name : '')
							file.img_pay_div_code = file.post_appendix_file_img_pay_div_code
						}else{

							file.cartl_number = item.cartl_number
							file.board_number = item.board_number
							file.bulletin_number = item.bulletin_number
							file.appendix_file_div_code = file.post_appendix_file_div_code
							file.appendix_file_url = file.post_appendix_file_org_url + (file.post_appendix_file_name ? '/' + file.post_appendix_file_name : '')
							file.img_pay_div_code = file.post_appendix_file_img_pay_div_code

						}
					}

				}
			}
		}
		,async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.mount()
		}
		, watch: {
			'item_comment.content':{
				deep: true
				, handler: function(call){
					this.$log.console('call', call)
					/*
					if(this.item_comment.upper_id){

						if(this.item_comment.upper_nick != call.slice(1, this.item_comment.upper_nick.length + 1)){
							this.item_comment.upper_id = ''
							this.item_comment.upper_nick = ''
						}
					}else{
						this.item_comment.upper_nick = ''
					}
					*/
				}
			}
			, $route(to, from){
				this.mount()
				this.$log.console(to, from)
			}
		}
	}
</script>

<style>
.hashtag { font-weight: normal !important;}
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }

.bright_radio input[type="checkbox"] { overflow: hidden; position: absolute; margin: 0; padding: 0; clip: rect(0, 0, 0, 0); width: 1px; height: 1px; border: 0; }
.bright_radio input[type="checkbox"] + label { padding-left: 34px; line-height: 24px; font-size: 14px; }
.bright_radio input[type="checkbox"] + label:before { content: ''; position: absolute; top: 0; left: 0; width: 24px; height: 24px; border: 1px solid var(--light-Gray02); box-sizing: border-box;}
.bright_radio input[type="checkbox"]:checked  + label:before { border: 1px solid var(--blue02);}
.bright_radio input[type="checkbox"]:checked  + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--blue02); }
.bright_radio input[type="checkbox"]:disabled + label:before { border: 1px solid var(--light-Gray02); }
.bright_radio input[type="checkbox"]:disabled + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--light-Gray02); border-radius: 12px; }

</style>